import "./Commons.css"
import { styled } from "@mui/material/styles"
import { Box, Button, Container, Grid, List, MenuList } from "@mui/material"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import { useEffect, useState } from "react"
import logoAzul from "../../assets/landing/LogoAZUL.svg"
import PhoneIcon from "@mui/icons-material/Phone"
import Link from "@mui/material/Link"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import * as React from "react"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"
import AyudaModal from "../Inicio/AyudaModal"
import { useLocation } from "react-router-dom"
import { makeStyles } from '@mui/styles';
import { UtmURls } from '../../components/Utils/Utils'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  logo: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-around',
  },
  spacer: {
    marginLeft: theme.spacing(2),
  },
  menu: {
    padding: 0,
  },
  popOverPaper: {
    width: '100%',
    marginTop: 40,
  },
}));

export default function Header(props) {
  const location = useLocation();
  const classes = useStyles();
  const [showHelp, setShowHelp] = useState(false);
  const [isInicio, setIsInicio] = useState(true);
  const [isCRM, setIsCRM] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const ColorButton = styled(Button)(({ theme }) => ({
    height: '38px',
    maxWidth: '210px',
    color: props.isFromInicio ? "#039ECC" : "#FFFFFF",
    backgroundColor: props.isFromInicio ? "#FFFFFF" : "#039ECC",
    "&:hover": {
      backgroundColor: props.isFromInicio ? "#FFFFFF" : "#039ECC",
    },
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    textTransform: 'none',
    right: '0px'
  }))
  const open = Boolean(anchorEl)
  const handleMovil = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenSeguros = (e) => {
    setAnchorEl2(e.currentTarget);
    window.dataLayer.push({
      event: 'ev_menu',
      option: 'Seguros',
    })
  };

  const handleCloseSeguros = () => {
    setAnchorEl2(null);
  };

  const [openL, setOpenL] = useState(false);

  const handleClick = () => {
    setOpenL(!openL);
  };

  useEffect(() => {
    if (location.pathname === "/inicio") {
      setIsInicio(true);
    } else {
      setIsInicio(false);
    }
    if (location.pathname.includes("/crm")) {
      setIsCRM(true);
    } else {
      setIsCRM(false);
    }
  }, [location.pathname]);

  return (
    <>
      { !isCRM &&
        <>
          {showHelp && (
            <AyudaModal
              isVisible={showHelp}
              handleClose={() => setShowHelp(!showHelp)}
            />
          )}
          <div className={classes.root}>
            <div className="header-common">
                <Container maxWidth="xl">
                    <Box sx={{ display: "grid" }}>
                      <Grid container className="header">
                        <Grid item xs={4} sm={2} md={2} lg={2} xl={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Link underline="none" href="https://inter.mx" style={{height: '27px'}} >
                              <img src={logoAzul} alt="" style={{height: '27px'}}/>
                            </Link>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={11}
                          sm={10}
                          md={10}
                          lg={9}
                          xl={9}
                          sx={{
                            display: {
                              xs: "none",
                              sm: "none",
                              md: "block",
                              lg: "block",
                              xl: "block",
                            },
                          }}
                          PaperProps={{
                            style: {
                                width: '360px',
                                borderRadius: 0
                            }
                        }}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            className="flex-xl"
                          >

                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                              <Link
                                underline="none"
                                target="_blank"

                                href={UtmURls("https://cocheseguro.inter.mx/")}

                                className="text-bold"
                                onClick={
                                  () =>
                                  window.dataLayer.push({
                                    event: 'ev_menu',
                                    option: 'Coche Seguro'
                                  })
                                }
                                >
                                Coche Seguro
                              </Link>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                              <Link
                                underline="none"
                                target="_blank"
                                href={UtmURls("https://mascotasegura.inter.mx/")}
                                className="text-bold"
                                onClick={
                                  () =>
                                  window.dataLayer.push({
                                    event: 'ev_menu',
                                    option:'Mascota Segura'
                                  })
                                }
                                >
                                Mascota Segura
                              </Link>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                              <Button
                                aria-controls="menu"
                                aria-haspopup="true"
                                onClick={handleOpenSeguros}
                              >
                                <span className="item-menu case-none text-bold">Conocer más</span>
                              </Button>
                              <Menu
                                style={{ marginTop: "5" }}
                                id="menu"
                                onClose={handleCloseSeguros}
                                anchorEl={anchorEl2}
                                open={Boolean(anchorEl2)}
                              >
                                <MenuList>
                                  <MenuItem>
                                    <Link
                                      color="Black"
                                      target="_blank"
                                      underline="none"
                                      href="https://www.inter.mx/blog/notas/destacadas"
                                    >
                                      Blog
                                    </Link>
                                  </MenuItem>
                                  <MenuItem>
                                    <Link
                                      color="Black"
                                      target="_blank"
                                      underline="none"
                                      href="https://www.inter.mx/promociones/1"
                                    >
                                      Promociones
                                    </Link>
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Grid>
                            <Grid item display='grid' justifyContent='flex-end' xs={3} sm={3} md={4} lg={3} xl={3}>
                                <ColorButton
                                  variant="contained"
                                  disableElevation
                                  onClick={() => setShowHelp(!showHelp)}
                                  startIcon={<PhoneIcon fontSize="small" />}
                                >
                                  ¿Necesitas ayuda?
                                </ColorButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={8} sm={9} md={9} lg={10} xl={10}
                          sx={{
                            display: {
                              xs: "block",
                              sm: "block",
                              md: "none",
                              lg: "none",
                              xl: "none",
                            },
                          }}
                        >
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                          >
                            <IconButton
                              id="basic-button"
                              aria-controls="basic-menu"
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={handleMovil}
                            >
                              <MenuIcon fontSize={'large'} />
                            </IconButton>
                            <Menu
                              id="basic-menu"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              <List
                                sx={{
                                  width: "100%",
                                  maxWidth: 360,
                                  bgcolor: "background.paper",
                                }}
                                component="nav"
                                aria-labelledby="nested-list-subheader"
                              >
                                <ListItemButton onClick={handleClick}>
                                  <ListItemText primary="Seguros" />
                                  {openL ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse
                                  in={openL}
                                  timeout="auto"
                                  unmountOnExit
                                  className="list-inicio"
                                >
                                  <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}>
                                      <ListItemText>
                                        <Link
                                          color="Black"
                                          target="_blank"
                                          underline="none"
                                          href={UtmURls("https://cocheseguro.inter.mx/")}
                                        >
                                          <span className="textMovil">Coche Seguro</span>
                                        </Link>
                                      </ListItemText>
                                      <ListItemText></ListItemText>
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }}>
                                      <ListItemText>
                                        <Link
                                          color="Black"
                                          target="_blank"
                                          underline="none"
                                          href={UtmURls("https://mascotasegura.inter.mx/seguro-mascota/")}
                                        >
                                          <span className="textMovil">
                                            Mascota Segura
                                          </span>
                                        </Link>
                                      </ListItemText>
                                      <ListItemText></ListItemText>
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }}>
                                      <ListItemText>
                                        <Link
                                          color="Black"
                                          target="_blank"
                                          underline="none"
                                          href="https://productos.inter.mx/vida-segura"
                                        >
                                          <span className="textMovil">Vida Segura</span>
                                        </Link>
                                      </ListItemText>
                                      <ListItemText></ListItemText>
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }}>
                                      <ListItemText>
                                        <Link
                                          color="Black"
                                          underline="none"
                                          target="_blank"
                                          href="https://productos.inter.mx/bien-seguro "
                                        >
                                          <span className="textMovil">Bien Seguro</span>
                                        </Link>
                                      </ListItemText>
                                      <ListItemText></ListItemText>
                                    </ListItemButton>
                                    <ListItemButton sx={{ pl: 4 }}>
                                      <ListItemText>
                                        <Link
                                          color="Black"
                                          underline="none"
                                          target="_blank"
                                          href="https://inter.mx/viaje-seguro"
                                        >
                                          <span className="textMovil">Viaja Seguro</span>
                                        </Link>
                                      </ListItemText>
                                      <ListItemText></ListItemText>
                                    </ListItemButton>
                                  </List>
                                </Collapse>
                                <ListItemButton>
                                  <ListItemText>
                                    <Link
                                      color="White"
                                      underline="none"
                                      target="_blank"
                                      href="https://inter.mx/about-us"
                                    >
                                      <span className="textMovil">
                                        Qui&eacute;nes Somos
                                      </span>
                                    </Link>
                                  </ListItemText>
                                </ListItemButton>
                                <ListItemButton>
                                  <ListItemText>
                                    <Link
                                      color="White"
                                      underline="none"
                                      target="_blank"
                                      href="https://inter.mx/blog"
                                    >
                                      <span className="textMovil">Blog</span>
                                    </Link>
                                  </ListItemText>
                                </ListItemButton>
                                <ListItemButton>
                                  <ListItemText>
                                    <Link
                                      color="White"
                                      underline="none"
                                      target="_blank"
                                      href="https://inter.mx/promotions"
                                    >
                                      <span className="textMovil">Promociones</span>
                                    </Link>
                                  </ListItemText>
                                </ListItemButton>
                                <ListItemButton>
                                  <ListItemText>
                                    <div style={{width: '84vw'}}>
                                      <ColorButton
                                        onClick={() => setShowHelp(!showHelp)}
                                        startIcon={<PhoneIcon fontSize="small" />}
                                      >
                                          ¿Necesitas ayuda?
                                      </ColorButton>
                                    </div>
                                  </ListItemText>
                                </ListItemButton>
                              </List>
                            </Menu>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                </Container>
            </div>
          </div>
        </>
      }
    </>
  )
}
