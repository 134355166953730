import React from "react";
import "./Inicio.css";
import { Grid, Typography, Box } from "@mui/material";
import PreguntasFrecuentes from "./PreguntasFrecuentes";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ReactComponent as LogoAxa } from "../../assets/landing/LogoAxa.svg";
import { ReactComponent as LogoGmx } from "../../assets/img/planesCotizacion/logo-general-de-salud.svg";
import { ReactComponent as LogoGnp } from "../../assets/landing/LogoGnp.svg";
import { ReactComponent as LogoMapfre } from "../../assets/landing/LogoMapfre.svg";
import FormularioCotizacion from "./FormularioCotizacion";
import { Container } from "@mui/system";
import excitedBrunette from '../../assets/img/Girl-smartphone.png'
//import LandingSlider from "../LandingV2/slider";
import FirstBlockLanding from "../LandingV2/first-block-landing";
import SecondBlockLanding from "../LandingV2/second-block-landing";
import PlansSlider from "../LandingV2/plansSlider";
import { useRef } from "react";
import { SlideShow } from "../LandingV2/SlideShow";
import CheckUp from "../../assets/img/CheckUp.svg";
import Maternidad from "../../assets/img/Maternidad.svg";
import Medicos from "../../assets/img/Medicos.svg";
import PlanDental from "../../assets/img/PlanDental.svg";
import { useMediaQuery, useTheme } from '@mui/material';
import { useOnScreen } from "../Utils/Utils";
import { INTER_CONTRATANTE } from "../Utils/Constantes";
import AntiFraudHome from "../antiFraude/AntiFraudHome";
import Modal from "../../components/modalBanners/Modal";

const cards = [
  {
    title: 'Maternidad',
    description: '¿Ya recibiste la gran noticia? Estarás respaldada con los gastos de maternidad, siempre y cuando estés asegurada antes del embarazo.',
    image: Maternidad
  },
  {
    title: 'Check Up Básico',
    description: '¿Necesitas exámenes médicos? Están incluidos para que puedas identificar y prevenir enfermedades como diabetes, cáncer, obesidad, entre otras.',
    image: CheckUp
  },
  {
    title: 'Médicos cerca de ti',
    description: '¿Necesitas un doctor? Podrás visitar a un especialista que este dentro de tu red médica  y sólo pagarás un porcentaje del total de la consulta.',
    image: Medicos
  },
  {
    title: 'Plan Dental',
    description: '¿Te hace falta ir al dentista? Tendrás beneficios adicionales como: Consultas, radiografías, endodoncias, entre otros.',
    image: PlanDental
  }
]

export default function Inicio(props) {
  const navigate = useNavigate();
  const plansRef = useRef();
  const cotizacionRef = useRef();
  const gallerySlider = document.getElementById('gallerySliderContainer');
  const { breakpoints } = useTheme();
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isMobile = useMediaQuery(breakpoints.down('sm'));
  const [showModal, setShowModal] = useState(true);
  const storageUser = JSON.parse(JSON.stringify(sessionStorage.getItem(INTER_CONTRATANTE)))
  const [userInfo, setUserInfo] = useState(storageUser)

  const handleScrollToPlanes = () => {
    plansRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const handleScrollToCotizacion = () => {
    cotizacionRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };


  const handleClickToTR1S = () => {
    window.location.href = 'https://teregalamosunseguro.inter.mx/';
  }

  const isVisible = useOnScreen(cotizacionRef)

  const galloMediaStep = () => {
    window.dataLayer.push({
      'event': 'form_step_0_view',
      'noCotizacion': userInfo && userInfo.folio ? userInfo.folio : undefined,
      'correo': userInfo && userInfo.correo ? userInfo.correo : undefined
    })
  }

  useEffect(()=>{
    galloMediaStep()
    setUserInfo(storageUser)
  },[storageUser])

  useEffect(() => {
    if (props.mantenimiento) {
      navigate("/mantenimiento");
    }
  }, []);

  let pos = { left: 0, x: 0 };

  const mouseMoveHandler = (e) => {
    const dx = e.clientX - pos.x;

    if (gallerySlider !== null) {
      gallerySlider.scrollLeft = pos.left - dx;
    }
  };

  const mouseUpHandler = () => {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    if (gallerySlider !== null) {
      gallerySlider.style.cursor = 'grab';
      gallerySlider.style.removeProperty('user-select');
    }
  };

  const mouseDownHandler = (e) => {
    pos = {
      left: gallerySlider?.scrollLeft ? gallerySlider?.scrollLeft - 42 : 0,
      x: e.clientX,
    };

    if (gallerySlider !== null) {
      gallerySlider.style.cursor = 'grabbing';
      gallerySlider.style.userSelect = 'none';
    }

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  return (
    <>
      {/* <SlideShow clickCotizacionAction={handleScrollToCotizacion}/> */}
      {(showModal) &&  (
        <Modal onClose={handleCloseModal} setShowModal={setShowModal}/>
      )}
      <FirstBlockLanding clickPlanesAction={handleScrollToPlanes} clickCotizacionAction={handleScrollToCotizacion}/>
      <SecondBlockLanding />
      <div style={{ maxHeight: isMobile ? '1520px' : isTablet ? '1270px' : 'auto' }} ref={plansRef}>
        <PlansSlider clickPlanesAction={handleScrollToPlanes}  handleScrollToCotizacion={handleScrollToCotizacion} />
      </div>
      <Box sx={{ backgroundColor: '#F2FAFC' }}>
        <Container maxWidth={'xl'}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "4vh 0" }}
          >
            <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h2" className="coberturas-adicionales__title">De acuerdo a la aseguradora y plan que elijas podrás recibir coberturas adicionales sin costo como:</Typography>
            </Grid>
            <Grid>
              <div
                className="gallery-slider-container"
                id="gallerySliderContainer"
                onMouseDown={mouseDownHandler}
              >
                <div className="gallery-slider" id="gallery">
                  {cards.map((card, id) => (
                    <div key={id} className="card">
                      <div className="card-content">
                        <h3>{card.title}</h3>
                        <p>{card.description}</p>
                      </div>
                      <div className="card-image">
                        <img src={card.image} alt={card.title} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Aqui va */}
      <Box sx={{ backgroundColor: '#FFF' }}>
        <Container style={{padding: '0 40px'}}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ padding: "4vh 0" }}
          >
            <Grid item xs={12} md={6} sx={{ textAlign: 'center'}}>
              <img
                loading="lazy"
                src={excitedBrunette}
                className="coberturas__img"
                alt="excited_brunette"
              />
            </Grid>
            <Grid item xs={12} md={6} sx={2}>
              <Typography variant="h2" className="coberturas-title">
                Además, podrás personalizar tu plan agregando coberturas extra a un costo preferencial
              </Typography>
              <ul className="coberturas-adicionales-list" style={{ paddingLeft: '40px', paddingTop: '8px' }}>
                <li>Check up para adultos mayores de 50 años</li>
                <li>Emergencia médica en el extranjero</li>
                <li>Atención médica sólo en mi estado</li>
                <li>Reconocimiento de antigüedad</li>
                <li>Sin deducible por accidentes</li>
              </ul>
            </Grid>
          </Grid>
        </Container>
      </Box>


      <div ref={cotizacionRef}>
        <FormularioCotizacion />
      </div>

      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <h2 style={{
          fontSize: '28px',
          fontWeight: '700',
          lineHeight: '34.13px',
          maxWidth: '708px',
          textAlign: 'center',
          marginBottom: '40px',
          marginLeft: '10px',
          marginRight: '10px'
        }}>Somos una empresa líder porque nos respaldan las mejores aseguradoras</h2>
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          maxWidth: '900px',
          rowGap: '40px'
        }}>
        {  <LogoAxa />}
          <LogoGmx style={{ height: "55px" }}/>
          <LogoGnp style={{ height: "50px" }}/>
          <LogoMapfre />
        </div>
      </div>
      <AntiFraudHome />
      <PreguntasFrecuentes />
    </>
  );
}
