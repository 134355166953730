import * as React from "react";

///Material
import { Container, Grid, Stack } from "@mui/material";
import { Divider, Tooltip, Box } from "@mui/material";

//API and Components
import Api from "../Api/Api";
import Loading from "../Commons/Loader";

//CSS  Logos
import TooltipIcon from "../../assets/img/planesCotizacion/TooltipIsolated.svg";

import "./PlanesCotizacion.css";

import CabeceraContratante from "./CabeceraContratante";
import TarjetasPlanesCotizacionDesktop from "./TarjetasPlanesCotizacionDesktop";
import TarjetasPlanesMobileTablet from "./TarjetasPlanesMobileTablet";
import AlertAntiFraudClose from "../antiFraude/AlertAntiFraudClose";
//import { descartarAXA } from '../Utils/Utils'

export default function PlanesCotizacion(props) {
  //TODO: PARA MAÑANA PREGUNTARLE A MAURICIO SI PODEMOS PASAR ESTA VARIABLE A LET, VAR O MEJOR USESTATE
  const dataObj = JSON.parse(sessionStorage.getItem("interContratante"));
  const [dataPlanes, setDataPlanes] = React.useState([]);
  const [plan, setPlan] = React.useState("");
  const [openLoader, setOpenLoader] = React.useState(true);

  const [nombrePlan, setNombrePlan] = React.useState("Esencial");

  const recargarDatosDesdeAsegurados = (nuevoPlan) => {
    getPlanesData(nuevoPlan);
  };

  const recargarCardsDeCambioPlan = (nuevoPlan) => {
    sessionStorage.setItem("selectedPlan", nuevoPlan);
    getPlanesData(nuevoPlan);
  };

  //Estilos condicionados
  const bgWhite = {
    backgroundColor: "#FFF",
    padding: "2%",
    borderRadius: "12px",
    marginButtom: "2%",
    with: "1140px",
  };
  const bgTransparent = {
    backgroundColor: "transparent",
    padding: '0 1rem'
  };
  const fondoStyle = {
    minHeight: "700px",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    backgroundColor: "#f4f4f4",
    // zIndex: "-50"
  };
  const fondoStyleMobile = {
    height: "100%",
    backgroundColor: "#f5f5f5",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  };
  const styleTagsCabecera = {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "24px",
  };

  const getPlanesData = (nuevoPlan) => {
    setOpenLoader(true);
    setPlan(nuevoPlan);
    Api.getPaquetes(nuevoPlan)
      .then((res) => {
        var arrDatos2 = res.data;
        var arrDatos = [];
        const idContratante = dataObj.id;
        var contadorElementos = 0;
        arrDatos2.forEach((cadaAseguradora, idx) => {
          if (
            nuevoPlan === "Blindado" &&
            cadaAseguradora.nombreAseguradora === "GENERAL DE SEGUROS"
          ) {
          } else if (cadaAseguradora.activo === 1) {
            arrDatos.push(cadaAseguradora);
          }
        });
        arrDatos.forEach((cadaAseguradora, idx) => {
          const idAseguradora = cadaAseguradora.id;
          const idPlan = cadaAseguradora.paquetes[0].id;
          Api.getSumaAsegurada(idAseguradora, idPlan, idContratante)
            .then((res2) => {
              cadaAseguradora["sumaTotal"] = res2.data.total;
              contadorElementos++;
              if (contadorElementos === arrDatos.length) {
                //aqui va 1
                setDataPlanes(arrDatos);

              }
            })
            .catch((error) => {
              console.error("Error on API getSumaAsegurada:", error.message);
            });
        });
        setOpenLoader(false);
      })
      .catch((error) => {
        console.error("Error on getPaquetes:", error);
        setOpenLoader(false);
      });
  };

  React.useEffect(() => {
    if (dataPlanes.length > 0 && openLoader == false){

      window.dataLayer.push({
        event : "medico_seguro_cotizacion",
        no_cotizacion : dataObj.folio,
        plan: plan.length === 0 ? nombrePlan : plan,
        telefono : dataObj.telefono,
        email : dataObj.correo,
        items:
          dataPlanes.map((plan, idx) => (
            {
              index: idx,
              aseguradora: plan.nombreAseguradora,
              costo_aseguradora_anual: plan.sumaTotal,
            }
            ))
          },
          );
      }
  }, [[]]);

  React.useEffect(() => {
    setOpenLoader(true);
    window.dataLayer.push({
      event: "form_step_3_view",
    });

    Api.getPaquetes("Esencial")
      .then((res) => {
        var arrDatos2 = res.data;
        var arrDatos = [];
        const idContratante = dataObj.id;
        var contadorElementos = 0;
        arrDatos2.forEach((cadaAseguradora, idx) => {
          ///TODO codigo MAL en linea abajo. pendiente revisar
          if (
            nombrePlan === "Blindado" &&
            cadaAseguradora.nombreAseguradora === "GENERAL DE SEGUROS"
          ) {
          } else if (cadaAseguradora.activo === 1) {
            arrDatos.push(cadaAseguradora);
          }
        });
        arrDatos.forEach((cadaAseguradora, idx) => {
          const idAseguradora = cadaAseguradora.id;
          const idPlan = cadaAseguradora.paquetes[0].id;
          Api.getSumaAsegurada(idAseguradora, idPlan, idContratante)
            .then((res2) => {
              cadaAseguradora["sumaTotal"] = res2.data.total;
              contadorElementos++;
              if (contadorElementos === arrDatos.length) {
                //aqui va 2
                setDataPlanes(arrDatos);
              }
              if (contadorElementos == arrDatos2.length) {
                setOpenLoader(false);
              }
            })
            .catch((error) => {
              console.error("Error on API getSumaAsegurada:", error.message);
            });
        });
      })
      .catch((error) => {
        console.error("Error on getPaquetes:", error);
        setOpenLoader(false);
      });
  }, [dataObj.id]);

  return (
    <div style={props.isMobile ? fondoStyleMobile : fondoStyle}>
      <Container fluid className="container-planes mw-container">
        <Grid container direction="row" className="mb-2 space-between-centered">
          <Grid container className="w-90" lg={6} sm={12} xs={12}>
            <Grid
                item
                className="vl-titulo"
                style={{ marginRight: '16px' }}
            >
                <Divider orientation="vertical" flexItem />
            </Grid>
            <Grid item xs={10} sm={10}>
                <h1 className="mb-0 txt-listo-titulo">¡Todo listo!</h1>
                <p className="txt-2">
                Ahora te toca elegir el plan de salud que más te guste.
                </p>
            </Grid>
          </Grid>
          <Grid
            item
            lg={5}
            sm={5}
            xs={3}
            className="hideOnMobile-2"
          >
            <Box
              sx={{
                display: "flex",
                width: "364px",
              }}
            >
              <Stack
                sx={{ width: "100%" }}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Box sx={styleTagsCabecera}>Número de cotización</Box>
                <Box>
                  <Tooltip title="Con este número podrás completar más tarde tu proceso de contratación, para que no tengas que llenar otra vez todos tus datos">
                    <img
                      alt="ico-tooltip"
                      src={TooltipIcon}
                      className="ico-tooltip"
                    />
                  </Tooltip>
                </Box>
              </Stack>
            </Box>
            <Box
              sx={{
                backgroundColor: "#fff",
                borderRadius: "10px",
                width: "364px",
                height: "34px",
                padding: "4% 0% 0% 5%",
                // justifyContent: "end",
                // textAlign: "right"
              }}
              className="w-235-tablet"
            >
              <Box sx={{ margin: "0 5% 0 0" }}>{dataObj.folio}</Box>
            </Box>
          </Grid>
        </Grid>

        <div
          style={props.isMobile ? bgTransparent : bgWhite}
          className="bg-white-tablet"
        >
          <CabeceraContratante
            recargarDatos={recargarDatosDesdeAsegurados}
            cambiarPlan={recargarCardsDeCambioPlan}
            planesData={dataPlanes}
          />
          <Box
            sx={{
              display: "flex",
              mt: 2,
              mb: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <AlertAntiFraudClose
              customStyles={{
                margin: {
                  xs: "5% 0% 5% 0%",
                  sm: "5% 0% 5% 0%",
                  md: "2% 0% 2% 0%",
                  lg: "2% 20% 2% 20%",
                },
              }}
            />
          </Box>
          <TarjetasPlanesCotizacionDesktop
            planesData={dataPlanes}
            plan={plan}
          />
          <TarjetasPlanesMobileTablet planesData={dataPlanes} />
        </div>
      </Container>
      <Loading open={openLoader} onClose={() => setOpenLoader(false)} />
    </div>
  );
}
